@charset 'utf-8';

@import 'global/colours';
@import 'global/variables';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import 'global/typography';
@import 'global/fontello';


// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-switch;
// @include foundation-table;
// Basic components
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
// @include foundation-pagination;
// @include foundation-tooltip;

// Containers
// @include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
// @include foundation-responsive-embed;
// @include foundation-tabs;
// @include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
// @include foundation-accordion-menu;
// @include foundation-drilldown-menu;
// @include foundation-dropdown-menu;

// Layout components
// @include foundation-off-canvas;
// @include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;

@import 'global/nav';
@import 'components/donut';
@import 'components/pills';
@import 'components/steps';

svg tspan.icon {
   font-family: "twm";
}

.bg-black {
  background-color: black;
  color: white;
  h1, h2, h3, h4, p {
    color: white;
  }
}

.bg-gold {
  background-color: $twm-gold;
  color: white;

  &-semi {
    background-color: $twm-gold-semi;
  }

}

.bg-white {
  background-color: white;
  color: black;

  &.pill {
    a {
      color: black;
      &:hover {
        color: $twm-gold;
      }
    }
  }
}
.gold {
  color: $twm-gold !important;
}


@include breakpoint(medium up) {
  .bg-black-gold {
    background: linear-gradient(to right, #000 , #000 49.999999%, $twm-gold 50%, $twm-gold);
    > .grid-container > .grid-padding-y > .cell {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
}



.hero, .sectionbg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .hero-title {
    text-align: center;
  }

  h1 {
    font-size: 3rem;

    @include breakpoint(medium up) {
      font-size: 5rem;
    }
    margin-bottom: 0;
    color: white;
  }
}

.hero {
  background-attachment: fixed;
}
.hero::before {
  content: '';
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	background-image: linear-gradient(to bottom,#000, transparent);
	opacity: 0.75;
  height: 200px;
}

.menu .button {
  @include button(false, #fff, $twm-gold, #fff, 'hollow');
  margin-bottom: 0;
}

.sticky-active {
  .menu .button {
    @include button(false, #000, $twm-gold, #000, 'hollow');
    margin-bottom: 0;
  }
}

.section-title {
  padding-top: 3rem;
  padding-bottom: 3rem;
}


.pt {
  padding-top: 3rem;
}
.pb {
  padding-bottom: 3rem;
}


.svgline {
  fill:none;stroke:#fff;stroke-width:2px;
}


.hero-title {
  text-align: center;
}

.contact-box {
  border: 1px solid white;
  padding: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

#tax-planning {
  color: white;
  text-align: center;
}

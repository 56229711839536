.step {
  color: white;
  width: 100%;
  &.odd {

    @include breakpoint(medium up) {
      width: 50%;
    }
  }

  &.even {
    text-align: right;
    width: 100%;
    @include breakpoint(medium up) {
      margin-left: 50%;
      width: 50%;
      text-align: left;
    }
  }
}


.pills-section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.pills-line {
  background-image: url(../../assets/img/line-2px.png);
  background-repeat: no-repeat;
  background-position: center ($pill / 2);
  .cell {
    background-image: url(../../assets/img/line-2px.png);
    background-repeat: no-repeat;
    background-position: center ($pill / 2);
  }
}
.pill {
  height: $pill;
  width: $pill;
  border-radius: 50%;
  display: inline-block;
  color: black;
  background-image: none;
  background-position:50% 50%;
  background-repeat:no-repeat;
  background-size: 60%;
  line-height: $pill;
  text-align: center;

  i {
    font-size: ($pill * 0.6);
  }
}
.pill-side .h5 {
  padding-top: ($pill / 2 - 1.4/2);
}

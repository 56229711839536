.icon-bank:before { content: '\e800'; } /* '' */
.icon-couple:before { content: '\e801'; } /* '' */
.icon-thumb:before { content: '\e802'; } /* '' */
.icon-envelope:before { content: '\e803'; } /* '' */
.icon-head:before { content: '\e804'; } /* '' */
.icon-heads:before { content: '\e805'; } /* '' */
.icon-jigsaw:before { content: '\e807'; } /* '' */
.icon-location:before { content: '\e809'; } /* '' */
.icon-man-circle:before { content: '\e80a'; } /* '' */
.icon-mobile:before { content: '\e80b'; } /* '' */
.icon-pennies:before { content: '\e80c'; } /* '' */
.icon-people:before { content: '\e80d'; } /* '' */
.icon-person:before { content: '\e80e'; } /* '' */
.icon-piggy:before { content: '\e80f'; } /* '' */
.icon-weefone:before { content: '\e810'; } /* '' */
.icon-house:before { content: '\e812'; } /* '' */
.icon-rocket:before { content: '\e813'; } /* '' */
.icon-switch:before { content: '\e814'; } /* '' */

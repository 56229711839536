
.header-wrap .banner .top{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    background-color:transparent;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    height:60px;
    left:0;
    overflow:hidden;
    padding:20px 0;
    position:fixed;
    right:0;
    top:0;
    -webkit-transition:height .35s ease;
    -o-transition:height .35s ease;
    transition:height .35s ease;
    width:100%;
    z-index:10
}
@media screen and (prefers-reduced-motion:reduce){
    .header-wrap .banner .top{
        -webkit-transition:none;
        -o-transition:none;
        transition:none
    }
}
@media (min-width:768px){
    .header-wrap .banner .top{
        height:105px
    }
}
.sticky-active .header-wrap .banner .top{
    background-color:#ffffffcc;
    border-width:0;
    -webkit-box-shadow:0 5px 30px 0 rgba(0,0,0,.23);
    box-shadow:0 5px 30px 0 rgba(0,0,0,.23);
    height:60px;
    z-index:900
}

.header-wrap .banner .top .top-inner-desktop{
    display:none;
    margin:0 30px 0 30px;
    width:100%
}
@media (min-width:768px){
    .header-wrap .banner .top .top-inner-desktop{
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex
    }
}
.header-wrap .banner .top .top-inner-mobile{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-flex:1;
    -ms-flex:1;
    flex:1;
    height:60px
}

.header-wrap .banner .top .top-inner-mobile .menu-title{
    margin-right:12px
}
@media (min-width:768px){
    .header-wrap .banner .top .top-inner-mobile{
        display:none
    }
}
.header-wrap .banner .top .left{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    display:none;
    position:relative
}
@media (min-width:768px){
    .header-wrap .banner .top .left{
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex
    }
}

@media (min-width:768px){
    .sticky-active .header-wrap .banner .top .left{
        display:block
    }
}

.header-wrap .banner .top .left .brand img {
    display:none;
}

@media (min-width:768px){
    .sticky-active .header-wrap .banner .top .left .brand img{
        display:block
    }
}

.header-wrap .banner .top .right{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    line-height:1.1;
    position:relative;

    ul {
      li {
        font-family: 'Montserrat', sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        color: white;
        a {
          color: white;
        }
      }
    }
}
.sticky-active .header-wrap .banner .top .right ul li {
  color: black;
  a {
    color: black;
  }
}
.header-wrap .banner .side-nav-toggle,
.header-wrap .banner [data-toggle=side-nav]{
    cursor:pointer;
    font-size:.8125rem;
    height:60px;
    pointer-events:auto;
    -webkit-transition:right .5s,background-color .2s ease-in-out,height .35s ease;
    -o-transition:right .5s,background-color .2s ease-in-out,height .35s ease;
    transition:right .5s,background-color .2s ease-in-out,height .35s ease;
    z-index:1040
}
@media screen and (prefers-reduced-motion:reduce){
    .header-wrap .banner .side-nav-toggle,
    .header-wrap .banner [data-toggle=side-nav]{
        -webkit-transition:none;
        -o-transition:none;
        transition:none
    }
}
@media (min-width:768px){
    .header-wrap .banner .side-nav-toggle,
    .header-wrap .banner [data-toggle=side-nav]{
        height:105px
    }
}
@media (max-width:767.98px){
    .header-wrap .banner .side-nav-toggle.desktop,
    .header-wrap .banner [data-toggle=side-nav].desktop{
        display:none
    }
}
.sticky-active .header-wrap .banner .side-nav-toggle,
.sticky-active .header-wrap .banner [data-toggle=side-nav]{
    height:60px
}

.header-wrap .banner .side-nav-toggle{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    color:transparent;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    position:fixed;
    right:1rem;
    top:0
}
@media (min-width:576px){
    .header-wrap .banner .side-nav-toggle{
        margin-right:30px
    }
}

.side-nav-active .header-wrap .banner .side-nav-toggle.desktop{
    right:250px
}
@media (min-width:768px){
    .side-nav-active .header-wrap .banner .side-nav-toggle.desktop{
        right:355px
    }
}
@media (min-width:992px){
    .side-nav-active .header-wrap .banner .side-nav-toggle.desktop{
        right:400px
    }
}


.header-wrap .banner .bottom .brand{
    display:block;
    // height:218px;
    padding:24px 0;
    position:absolute;
    left: 1rem;
    z-index:11
}
.top-inner-desktop .brand img{
    height: 50px;
    width: auto;
}
// @media (min-width:768px){
//     .header-wrap .banner .bottom .brand{
//         height:228px
//     }
// }
//
// .header-wrap .banner .bottom .brand img,
// .header-wrap .banner .bottom .brand svg{
//     height:170px
// }
// @media (min-width:768px){
//     .header-wrap .banner .bottom .brand img,
//     .header-wrap .banner .bottom .brand svg{
//         height:180px
//     }
// }

.navburger {
  @include hamburger($twm-gold, $twm-grey, 32px, 32px);
}

// html body#body.sticky-active header.header-wrap div.banner div.bottom a.brand img.logo {
//   display: none;
// }

@keyframes donut-chart-fill {
  to { stroke-dasharray: 0 100; }
}

.donut {
  width: 100%;
  // font-size: 16px;
}
.donut-ring {
  stroke: $twm-grey;
}

.donut-segment {
  animation: donut-chart-fill 1s reverse ease-in;
  transform-origin: center;
  stroke: white;
}

.donut-icon {
  fill: white;
}
.donut-text {
  color: $twm-gold;
  text-align: center;
}

.donut-label {
  font-size: 0.28em;
  font-weight: 500;
  line-height: 1;
  fill: #000;
  transform: translateY(0.25em);
}

.donut-percent {
  font-size: 0.5em;
  // fill: #FF6200;
  line-height: 1;
  transform: translateY(0.5em);
}
